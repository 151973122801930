import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { Color } from "three";
import { initialColors } from "./constants";

export function Shoe(props) {
  const ref = useRef();
  const { scene, nodes, materials } = useGLTF(
    "https://www.threekit.com/hubfs/SolutionEngineering/RunningShoe_Flat_Smaller.glb"
  );
  const customMaterials = {};

  console.log("Shoe shoeConfig prop", props.shoeConfig);
  Object.keys(nodes).forEach((key) => {
    const node = nodes[key];
    if (node.material !== undefined) {
      console.log("key", key);
      customMaterials[key] = node.material.clone();
      if (
        props.shoeConfig !== undefined &&
        props.shoeConfig[key] !== undefined
      ) {
        customMaterials[key].color = new Color(
          Number.parseInt(props.shoeConfig[key], 16)
        );
      } else {
        customMaterials[key].color = new Color(
          Number.parseInt(initialColors[key], 16)
        );
      }
    }
  });

  //console.log('nodes', nodes)
  //console.log('materials', materials)
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.set(
      Math.cos(t / 4) / 30 + -0.05,
      0.75 + Math.sin(t / 5) / 8,
      0.2 + Math.sin(t / 4) / 8
    );
    ref.current.position.y = (0.5 + Math.cos(t / 2)) / 7;
  });
  return (
    <group ref={ref} scale={[50, 50, 50]}>
      {Object.keys(customMaterials).map((key) => {
        return (
          <mesh
            receiveShadow
            key={key}
            castShadow
            geometry={nodes[key].geometry}
            material={customMaterials[key]}
            {...props}
          ></mesh>
        );
      })}
    </group>
  );
}

useGLTF.preload(
  "https://www.threekit.com/hubfs/SolutionEngineering/RunningShoe_Flat_Smaller.glb"
);
