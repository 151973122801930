export const toHexColor = (color) => {
  const r = Math.round(Math.max(0, Math.min(color.r * 255, 255)))
    .toString(16)
    .padStart(2, "0");
  const g = Math.round(Math.max(0, Math.min(color.g * 255, 255)))
    .toString(16)
    .padStart(2, "0");
  const b = Math.round(Math.max(0, Math.min(color.b * 255, 255)))
    .toString(16)
    .padStart(2, "0");
  return `${r}${g}${b}`;
};
