import styled from "styled-components";
import { theme } from "../../constants";

export const ChatbotWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  margin: 12px;

  background-color: #ffffffcc;
  border-radius: 3px;
  box-shadow: 2.5px 2.5px 10px #11111133;

  height: max-content;
  width: ${(props) => (props.collapsed ? "36px" : "340px")};
  max-height: ${(props) => (props.collapsed ? "36px" : "1000px")};
  border-radius: ${(props) => (props.collapsed ? "18px" : "3px")};
  overflow: hidden;
  transition: all 0.3s;

  @media only screen and (max-width: 700px) {
    width: ${(props) => (props.collapsed ? "36px" : "calc(100vw - 24px)")};
  }
`;

export const Content = styled.div`
  padding: 14px 18px;
  width: 340px;

  @media only screen and (max-width: 700px) {
    width: calc(100vw - 24px);
  }
`;

export const WidgetButton = styled.button`
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 15px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: ${theme.green};
  margin-top: 16px;
`;

export const Line = styled.hr`
  width: 84px;
  height: 1px;
  outline: none;
  border: none;
  background: #3e597344;
  margin-top: 12px;
  margin-bottom: 32px;
`;

export const Subtitle = styled.div`
  display: ${(props) => (props.hide ? "none" : "block")};
  font-size: 14px;
  color: #3e5973bb;
  color: ${theme.darkGreen};
  width: 80%;
`;

export const ActionArea = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  justify-content: center;
  margin-top: 20px;
`;

export const Error = styled.div`
  display: ${(props) => (props.hide ? "none" : "block")};
`;

export const ButtonWakeUp = styled.button`
  width: 120px;
  height: 38px;
  margin: 20px 0px;

  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: ${theme.darkGreen};
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #2ee59d;
    background-color: ${theme.mint};
    box-shadow: 0px 15px 20px rgba(120, 196, 162, 0.4);
    color: #fff;
    transform: translateY(-3px);
  }
`;

export const ChatResponse = styled.div`
  display: ${(props) => (props.hide ? "none" : "block")};
  background-color: ${theme.grey};
  padding: 1px 8px;
  margin: 10px 0px;
  color: #111;
  font-size: 13px;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
`;

export const ChatRequest = styled.div`
  display: ${(props) => (props.hide ? "none" : "block")};
`;

export const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  height: 34px;
  outline: none;
  padding: 8px 8px;
  /* font-size: 16px; */
  border-radius: 5px;
  border-top-left-radius: 0px;
  max-height: 330px;
  border: 1px solid #bfbfbf;

  transition: all 0.1s;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  height: 36px;
  margin-top: 8px;

  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: ${theme.darkGreen};
  background-color: #fff;
  background-color: ${(props) => (props.disable ? "#ffffff00" : "#ffffffff")};
  border: none;
  border-radius: 45px;
  box-shadow: ${(props) =>
    !props.disable
      ? "0px 8px 15px rgba(0, 0, 0, 0.1)"
      : "0px 8px 15px rgba(0, 0, 0, 0)"};
  cursor: ${(props) => (props.disable ? "default" : "pointer")};
  transition: all 0.3s ease 0s;
  outline: none;

  ${(props) =>
    props.disable
      ? ""
      : `
          &:hover {
            background-color: #2ee59d;
            background-color: ${theme.mint};
            box-shadow: 0px 15px 20px rgba(120, 196, 162, 0.4);
            color: #fff;
            /* transform: translateY(-3px); */
          }
  `}
`;
