export const shoeKeys = [
  "Laces",
  "Back-Tab_Mesh-Detail",
  "Back-Tab_Mesh",
  "Interior",
  "Label",
  "Main_Mesh",
  "Tongue_Detail",
  "Laces_Tabs",
  "Side_Wrap",
  "Sole-Wrap",
  "Sole_Color",
  "Side_Mesh",
];

export const initialColors = {
  Laces: "000000",
  "Back-Tab_Mesh-Detail": "82cda9",
  "Back-Tab_Mesh": "ffffff",
  Interior: "ffffff",
  Label: "29826b",
  Main_Mesh: "ffffff",
  Tongue_Detail: "82cda9",
  Laces_Tabs: "000000",
  Side_Wrap: "003233",
  "Sole-Wrap": "29826b",
  Sole_Color: "000000",
  Side_Mesh: "82cda9",
};

export const attributeMapping = {
  Laces: "Lace Color",
  "Back-Tab_Mesh-Detail": "Back Detail Color",
  "Back-Tab_Mesh": "Body Main Color",
  Interior: "Back Detail Color",
  Label: "Back Detail Color",
  Main_Mesh: "Body Main Color",
  Tongue_Detail: "Back Detail Color",
  Laces_Tabs: "Back Detail Color",
  Side_Wrap: "Wrap Side Color",
  "Sole-Wrap": "Sole Wrap Color",
  Sole_Color: "Sole Color",
  Side_Mesh: "Side Mesh Color",
};

export const theme = {
  green: "#2A826B",
  darkGreen: "#044849",
  mint: "#78C4A2",
  black: "#021B15",
  peach: "#FFBA9B",
  orange: "#FA8D71",
  neonOrange: "#FF6B42",
  grey: "#E8EEF0",
};
