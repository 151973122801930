import { useState, useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import axios from 'axios'
import { attributeMapping } from '../../constants'
import { toHexColor } from '../../utils'
import {
  ActionArea,
  ChatbotWrapper,
  Title,
  Line,
  Subtitle,
  Content,
  ButtonWakeUp,
  ChatResponse,
  WidgetButton,
  TextArea,
  SubmitButton
} from './chatbot.styles'
import MenuIcon from '../MenuIcon'
import RemoveIcon from '../RemoveIcon'

const DEFAULT_PLACEHOLDER = 'Give me some details...'
const DEFAULT_RESPONSE_MESSAGE =
  "What kind of color scheme would you like to get started? If you're not sure, you can start by asking for a shoe in your favourite colors, or with the theme of your sports teams or even shoes to match your current fit."
const DEFAULT_USER_MESSAGE = ''

const HOST = 'https://ai-server.demo.threekit.com'
// const HOST = 'http://localhost:8000'

const _lut = []

for (let i = 0; i < 256; i++) {
  _lut[i] = (i < 16 ? '0' : '') + i.toString(16)
}

export function generateUUID () {
  // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/21963136#21963136

  const d0 = (Math.random() * 0x100000000) | 0
  const d1 = (Math.random() * 0x100000000) | 0
  const d2 = (Math.random() * 0x100000000) | 0
  const d3 = (Math.random() * 0x100000000) | 0
  const uuid = `${
    _lut[d0 & 0xff] +
    _lut[(d0 >> 8) & 0xff] +
    _lut[(d0 >> 16) & 0xff] +
    _lut[(d0 >> 24) & 0xff]
  }-${_lut[d1 & 0xff]}${_lut[(d1 >> 8) & 0xff]}-${
    _lut[((d1 >> 16) & 0x0f) | 0x40]
  }${_lut[(d1 >> 24) & 0xff]}-${_lut[(d2 & 0x3f) | 0x80]}${
    _lut[(d2 >> 8) & 0xff]
  }-${_lut[(d2 >> 16) & 0xff]}${_lut[(d2 >> 24) & 0xff]}${_lut[d3 & 0xff]}${
    _lut[(d3 >> 8) & 0xff]
  }${_lut[(d3 >> 16) & 0xff]}${_lut[(d3 >> 24) & 0xff]}`

  // .toUpperCase() here flattens concatenated strings to save heap memory space.
  return uuid.toUpperCase()
}

const userId = generateUUID()
console.log('userId', userId)

const Header = () => {
  return (
    <>
      <Title>A NEW BUYING EXPERIENCE</Title>
      <Line />
    </>
  )
}

const Thinking = () => {
  const [dots, setDots] = useState(0)
  const timeoutRef = useRef()

  const minDots = 0
  const maxDots = 3

  useEffect(() => {
    const updateDots = n => {
      setDots(n === maxDots ? minDots : n + 1)
      timeoutRef.current = setTimeout(() => {
        updateDots(n === maxDots ? minDots : n + 1)
      }, 0.3 * 1000)
    }

    updateDots(2)

    return () => clearTimeout(timeoutRef.current)
  }, [])

  const visibleDots = new Array(dots).fill(null).reduce(output => {
    return output + '.'
  }, '')

  const hiddenDots = new Array(maxDots - dots).fill(null).reduce(output => {
    return output + '.'
  }, '')

  return (
    <>
      thinking{visibleDots}
      <span style={{ color: 'white' }}>{hiddenDots}</span>
    </>
  )
}

const ChatbotIntro = props => {
  const { show, handleClick } = props
  if (!show) return null
  return (
    <ChatbotWrapper>
      <Content>
        <Header />
        <Subtitle>
          Design your perfect shoe with the help of our AI stylist
        </Subtitle>
        <ActionArea>
          <ButtonWakeUp onClick={handleClick}>Lets go!</ButtonWakeUp>
        </ActionArea>
      </Content>
    </ChatbotWrapper>
  )
}

export const Chatbot = props => {
  const [showIntro, setShowIntro] = useState(true)
  const [isExpanded, setIsExpanded] = useState(true)
  const [userMessage, setUserMessagae] = useState(DEFAULT_USER_MESSAGE)
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER)
  const [responseMessage, setResponseMessage] = useState(
    DEFAULT_RESPONSE_MESSAGE
  )
  const [requestInProgress, setRequestInProgress] = useState(false)
  const textareaRef = useRef()

  useEffect(() => {
    const initAI = async () => {
      setRequestInProgress(true)
      // const response = await axios.post("https://ai-server.demo.threekit.com/");
      //   setResponseMessage(response.data.data.choices[0].message.content);
      setRequestInProgress(false)
    }

    initAI()
  }, [])

  const handleChangeUserMessage = e => setUserMessagae(e.target.value)

  const handleSubmitMessage = async () => {
    setRequestInProgress(true)
    const response = await axios.post(`${HOST}/chat`, {
      userId,
      message: userMessage
    })
    const responseStr = response.data.str
    console.log('response.data', JSON.stringify(response.data, null, 2))

    if (response.data.str.length > 2) {
      const responseJsonStr = responseStr.substring(
        responseStr.indexOf('{'),
        responseStr.lastIndexOf('}') + 1
      )

      let responseJson

      try {
        responseJson = JSON.parse(responseJsonStr)
      } catch {
        responseJson = { err: 'something went wrong' }
      }
      const preppedConfiguration = Object.entries(attributeMapping).reduce(
        (output, [attrName, key]) => {
          if (!responseJson[key]) return output
          return Object.assign(output, {
            [attrName]: toHexColor(responseJson[key])
          })
        },
        {}
      )
      props.setShoeConfig(preppedConfiguration)
      console.log(
        'Setting response message (1): ',
        responseStr.replace(responseJsonStr, '')
      )
      setResponseMessage(responseStr.replace(responseJsonStr, ''))
    } else {
      console.log('Setting response message (2): ', responseStr)
      if (responseStr === '\n') {
        console.log('true')
        setResponseMessage(
          'Please provide some additional insight to the colors you may like so I can make this desisgn the best it can be!'
        )
      } else {
        JSON.stringify(responseStr)
        setResponseMessage(responseStr)
      }
    }

    console.log('Setting placeholder: ', userMessage)
    setPlaceholder(userMessage)
    setUserMessagae('')
    setRequestInProgress(false)
  }

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return
    handleSubmitMessage()
  }

  const handleKeyUp = e => {
    textareaRef.current.style.height = `${e.target.scrollHeight}px`
  }

  if (showIntro)
    return (
      <ChatbotIntro show={showIntro} handleClick={() => setShowIntro(false)} />
    )

  return (
    <ChatbotWrapper collapsed={!isExpanded}>
      <WidgetButton onClick={() => setIsExpanded(!isExpanded)}>
        <MenuIcon />
      </WidgetButton>
      <Content>
        <Header />
        <ChatResponse id='ai-response'>
          <ReactMarkdown>
            {responseMessage.length > 2 ? responseMessage : ''}
          </ReactMarkdown>
        </ChatResponse>
        <TextArea
          ref={textareaRef}
          rows={7}
          value={userMessage}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          onChange={handleChangeUserMessage}
          disabled={requestInProgress}
          placeholder={placeholder}
        />
        <SubmitButton
          onClick={handleSubmitMessage}
          disable={requestInProgress}
          // disabled={}
        >
          {requestInProgress ? <Thinking /> : 'Send'}
        </SubmitButton>
      </Content>
    </ChatbotWrapper>
  )
}
